<template>
  <div class="NF bg-verde row justify-center">
    <div>
      <div class="shared row justify-center q-my-md q-gutter-x-sm">
        <q-input bg-color="white" dense outlined class="col-3" v-model="descServico" type="text" label="Serviço" />
        <q-input bg-color="white" dense outlined class="col-2" v-model="valServico" type="number" label="Valor" />
        <q-btn dense glossy no-caps color="white" class="col-2" outline label="Calcular" @click="recalc" />
        <q-btn dense glossy no-caps color="white" class="col-2" outline icon="download" label="Baixar" @click="onCastImage" />
        <q-btn dense glossy no-caps color="white" class="col-2" outline icon="reply" label="Voltar" @click="this.$router.push('/sells')" />
      </div>
      <div class="Notinha bg-white q-mb-xl" style="width: 650px">
        
        <q-separator spaced inset size="1px" color="white" />

        <div class="title row justify-around q-my-md q-mt-xl">
          <div class="col-8 text-h5 text-bold text-left q-pl-md">
            {{ enterprise }}
          </div>
          <div class="col-4">
            <q-img
              :src="srcLogo"
              alt="logo"
              width="90px"
            />
          </div>
        </div>

        <q-separator spaced inset size="5px" color="black" />
        
        <div class="text-h4">Comprovante de Compra</div>

        <q-separator spaced inset size="5px" color="black" />

        <div>
          <div class="codData row justify-between q-mx-xl text-subtitle1 text-bold">
            <div>Código Controle: {{ codControl }}</div>
            <div>Data: {{ dataVenda }}</div>
          </div>
          <div class="notNF q-my-md text-h6">
            *** NÃO É DOCUMENTO FISCAL ***
          </div>
        </div>

        <q-separator spaced inset size="5px" color="black" />

        <div class="table q-mx-xl q-mt-lg">
          <div class="header row text-italic text-bold">
            <div class="col-6">{{ cols[0].label }}</div>
            <div class="col-2">{{ cols[1].label }}</div>
            <div class="col-2">{{ cols[2].label }}</div>
            <div class="col-2">{{ cols[3].label }}</div>
          </div>
    
          <q-separator spaced size="2px" color="black" />

          <div class="body" v-for="(row, index) in rows" :key="index">
            <div class="card par row" v-if="index % 2 === 0">
              <div class="col-6 text-left q-pl-sm">
                {{ row.GE_TEAM + ' - ' + row.GE_MODEL + ' (' + row.GE_GENDER + ') | ' + row.GE_SIZE  }}
              </div>
              <div class="col-2 text-center">{{ row.GE_QUANTITY }}</div>
              <div class="col-2 text-right">{{ parseFloat(row.GE_SALEVALUE / row.GE_QUANTITY).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</div>
              <div class="col-2 text-right q-pr-sm">{{ parseFloat(row.GE_SALEVALUE).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</div>
            </div>
            <div class="card impar row" v-else>
              <div class="col-6 text-left q-pl-sm">
                {{ row.GE_TEAM + ' - ' + row.GE_MODEL + ' (' + row.GE_GENDER + ') | ' + row.GE_SIZE  }}
              </div>
              <div class="col-2 text-center">{{ row.GE_QUANTITY }}</div>
              <div class="col-2 text-right">{{ parseFloat(row.GE_SALEVALUE / row.GE_QUANTITY).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</div>
              <div class="col-2 text-right q-pr-sm">{{ parseFloat(row.GE_SALEVALUE).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</div>
            </div>
          </div>

          <div class="card" />
          <q-separator class="q-my-sm" size="2px" color="black" />

          <div class="resumo column">

            <div class="row justify-end">
              <div class="text-right col-7">SubTotal:</div>
              <div class="text-right col-3">{{ parseFloat(subtotal).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</div>
            </div>
            <div class="row justify-end">
              <div class="text-right col-9">{{ dServico }}:</div>
              <div class="text-right col-3">{{ parseFloat(vServico).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</div>
            </div>
            <div class="row justify-end text-bold">
              <div class="text-right col-7">Total:</div>
              <div class="text-right col-3">{{ parseFloat(total).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</div>
            </div>

          </div>
        </div>

        <q-separator spaced inset size="5px" color="black" />

        <div class="notNF q-mt-md q-mb-xl text-h6">
          *** NÃO É DOCUMENTO FISCAL ***
        </div>

        <q-separator spaced inset size="1px" color="white" />
      </div>
    </div>
  </div>
</template>

<script>
import * as htmlToImage from 'html-to-image';
import { supabase } from '../../supabase';
import DateFormat from '../../helpers/DateFormat';
import Slug from '../../helpers/Slug';

export default {
  data() {
    return {
      srcLogo: null,
      enterprise: localStorage.getItem('enterprise'),
      codControl: null,
      dataVenda: null,
      descServico: 'Serviços',
      dServico: 'Serviços',
      valServico: 0,
      vServico: 0,
      subtotal: 0,
      total: 0,
      cols: [
        { name: 'GE_TEAM',       align: 'center',    label: 'Produto',         field: 'GE_TEAM',       sortable: true },
        { name: 'GE_QUANTITY',       align: 'center',    label: 'Qtdade',         field: 'GE_QUANTITY',       sortable: true,
          format: val => parseInt(val)
        },
        { name: 'GE_SALEVALUE',       align: 'center',    label: 'Vlr. Unit.',         field: 'GE_SALEVALUE',       sortable: true,
          format: val => parseFloat(val).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})
        },
        { name: 'GE_SALEVALUE',       align: 'center',    label: 'Vlr. Total',         field: 'GE_SALEVALUE',       sortable: true,
          format: val => parseFloat(val).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})
        },
      ],
      rowsInicial: [],
      rows: [],
    }
  },
  methods: {
    recalc() {
      this.dServico = this.descServico;
      this.vServico = this.valServico;
      let qtdVendida = 0;
      let proporcional = 0;
      this.subtotal = 0;
      this.rows = this.rowsInicial;

      this.rows.map(row => {
        qtdVendida += row.GE_QUANTITY;
      })

      proporcional = parseFloat(this.valServico / qtdVendida).toFixed(10);

      this.rows = this.rows.map(linha => {
        return {
          GE_TEAM: linha.GE_TEAM,
          GE_MODEL: linha.GE_MODEL,
          GE_SIZE: linha.GE_SIZE,
          GE_GENDER: linha.GE_GENDER,
          GE_QUANTITY: linha.GE_QUANTITY,
          GE_SALEVALUE: parseFloat(linha.GE_SALEVALUE - (proporcional * linha.GE_QUANTITY))
        }
      })

      this.rows.map(sub => {
        this.subtotal += sub.GE_SALEVALUE
      })

      this.RecalcularTotal();

    },
    RecalcularTotal() {
      this.total = 0;
      this.total += parseFloat(this.valServico);
      this.total += parseFloat(this.subtotal);
    },
    onCastImage() {
      htmlToImage.toPng(
        document.querySelector('.Notinha'),
        { quality: 1 }
      ).then(dataURL => {
          var link = document.createElement('a');
          link.download = 'comprovante-compra.png';
          link.href = dataURL;
          link.click();
        })
    },
    async executeQuery() {
      //~> CARREGANDO A LOGO DO CLIENTE DO STORAGE
      const empresa = Slug(localStorage.getItem('enterprise'))
      const img = await supabase
        .storage
        .from('logos')
        .getPublicUrl(empresa + '.png')

      this.srcLogo = img.publicURL;

      //~> INFORMAÇÕES PARA ALIMENTAR OS CAMPOS DO COMPROVANTE
      const controle = sessionStorage.getItem('infoComprovante');
      this.codControl = String(controle).replace('0.', '')

      if(controle.length > 8) {
        const { data } = await supabase
          .from('GE_SELLS')
          .select('*')
          .filter('GE_COD_CONTROL', 'eq', controle)

        this.rowsInicial = data;
        this.dataVenda = DateFormat(data[0].GE_DATEPURCHASE)

        data.map(ttl => {
          this.subtotal += ttl.GE_SALEVALUE
        })
      } else {
        const { data } = await supabase
          .from('GE_SELLS')
          .select('*')
          .filter('RECNO', 'eq', controle)
        this.rowsInicial = data;
        this.dataVenda = DateFormat(data[0].GE_DATEPURCHASE)

        data.map(ttl => {
          this.subtotal += ttl.GE_SALEVALUE
        })
      }

      this.recalc();
    }
  },
  created() {
    this.executeQuery();
  }
}
</script>

<style lang="scss">
  @import url('../../globais/GlobalStyles.scss');
  .NF {
    height: 100%;

    .card {
      height: 80px;
      align-content: center;
    }
    .par {
      background-color: #fff;
    }

    .impar {
      background-color: #eee;
    }
  }
</style>
